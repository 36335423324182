import React, { useState } from 'react';
import TextInput from '../../../shared/Form/TextInput';
import Textarea from '../../../shared/Form/Textarea';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../shared/Spinner';
import axios from '../../../api/axios';
import { validateEmail } from '../../../shared/Helpers/validation';
import FormSuccess from './form-success';

const defaultErrors = {
  firstName: 'required',
  lastName: 'required',
  email: 'required',
  phoneNumber: 'required',
  message: 'required',
};

const formElements = [
  {
    id: 'lastName',
    name: 'lastName',
    label: 'First Name',
    type: 'text',
    required: true,
  },
  {
    id: 'firstName',
    name: 'firstName',
    label: 'Last Name',
    type: 'text',
    required: true,
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    type: 'email',
    required: true,
  },
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    label: 'Phone number',
    type: 'tel',
    required: true,
  },
  {
    id: 'message',
    name: 'message',
    label: 'Message',
    type: 'textarea',
    required: true,
  },
];

const ContactForm = () => {
  const { t } = useTranslation();
  const formTitle = t('formTitle', { returnObjects: true });
  const [formData, setFormData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState({ ...defaultErrors });
  const validations = {
    firstName: {
      required: 'First name is required',
    },
    lastName: {
      required: 'Last name is required',
    },
    phoneNumber: {
      required: 'Phone number is required',
    },
    email: {
      required: 'Email is required',
      isEmail: 'Invalid email address',
    },
    message: {
      required: 'Message is required',
    },
  };

  const handleChange = (e) => {
    setIsSuccess(false);
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (validations[name].required) {
      if (!value?.trim()?.length > 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'required',
        }));
      } else if (name === 'email' && !validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'isEmail',
        }));
      } else {
        setErrors((prevErrors) => {
          if (prevErrors[name]) delete prevErrors[name];
          return prevErrors;
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    if (isLoading) return;
    e.preventDefault();
    setIsSubmitted(true);

    if (Object.keys(errors).length > 0) return;

    try {
      setIsLoading(true);
      await axios.post('/api/contacts', {
        data: formData,
      });
      setErrors({ ...defaultErrors });
      setIsSubmitted(false);
      setFormData({});
      setIsSuccess(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="px-6 mb-20-lg">
      <div className="max-w-900 mx-auto py-20-xxl py-12">
        <p className="text-blue-950 text-5xl-xxl text-4xl-lg text-3xl mb-2 text-center w-75-lg mx-auto">
          {formTitle[0]} <strong>{formTitle[1]}</strong>
        </p>
        <p className="text-blue-950 text-lg-xxl text-base mb-8 text-center w-60-lg mx-auto">
          {t('formDescription')}
        </p>
        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6">
          {formElements.map((el) => {
            const { name } = el;
            let errorMessage;
            if (errors[name] && isSubmitted) {
              errorMessage = validations[name][errors[name]];
            }
            const label = t(`${el.label}`);
            if (el.type === 'textarea') {
              return (
                <div key={el.id} className="grid-span-2">
                  <Textarea
                    {...el}
                    label={label}
                    errorMessage={errorMessage && t(`${errorMessage}`)}
                    value={formData[el?.name] ?? ''}
                    onChange={handleChange}
                  />
                </div>
              );
            }
            return (
              <div key={el.id} className="grid-span-1-lg grid-span-2">
                <TextInput
                  {...el}
                  label={label}
                  errorMessage={errorMessage && t(`${errorMessage}`)}
                  value={formData[el?.name] ?? ''}
                  onChange={handleChange}
                />
              </div>
            );
          })}
          <div className="flex justify-center mt-12-lg grid-span-2 text-center">
            <button className="flex items-center justify-center bg-blue-400 text-white py-4-xxl py-3 px-16-xxl px-12 text-base-xl text-sm font-medium border border-blue-400 relative w-full w-auto-lg">
              <span
                className={`flex items-center justify-center ${isLoading ? 'opacity-0' : ''}`}
              >
                {t('Send')}
                <StaticImage
                  src="../../../images/icons/send.svg"
                  height={16}
                  width={18}
                  layout="fixed"
                  className="ms-3"
                  alt="Send icon"
                />
              </span>
              {isLoading && (
                <span className="flex items-center justify-center absolute left-50 top-50 -translate-50">
                  <Spinner />
                </span>
              )}
            </button>
          </div>
          {isSuccess && (
            <FormSuccess message={t('Your submission has been sent')} />
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
