import React from 'react';
import PropTypes from 'prop-types';

const ContactInfoCard = ({ label, value, children }) => {
  return (
    <div className="flex w-30-lg mt-6">
      <div className="me-4">{children}</div>
      <div className="flex flex-col">
        <span className="text-lg-xxl text-base text-white inline-block">
          {label}
        </span>
        <span className="text-lg-xxl text-base text-white inline-block font-bold">
          {value}
        </span>
      </div>
    </div>
  );
};

ContactInfoCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
};

export default ContactInfoCard;
