import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import ContactInfoCard from './ContactInfoCard';
import { useTranslation } from 'react-i18next';

const ContactInfo = () => {
  const { t } = useTranslation();
  const { title, phoneNumber, email, office } = t('contact', {
    returnObjects: true,
  });

  const list = [
    {
      Icon: () => (
        <StaticImage
          quality={100}
          src="../../../images/contact/mail.svg"
          width={76}
          height={76}
          layout="fixed"
          className="-translate-y-20"
          alt="Main icon"
        />
      ),
      label: email.label,
      value: email.value,
    },
    {
      Icon: () => (
        <StaticImage
          quality={100}
          src="../../../images/contact/location.svg"
          width={76}
          height={76}
          layout="fixed"
          className="-translate-y-20"
          alt="Location icon"
        />
      ),
      label: office.label,
      value: office.value,
    },
    {
      Icon: () => (
        <StaticImage
          quality={100}
          src="../../../images/contact/phone.svg"
          width={76}
          height={76}
          layout="fixed"
          className="-translate-y-20"
          alt="phone icon"
        />
      ),
      label: phoneNumber.label,
      value: phoneNumber.value,
    },
  ];

  return (
    <div className="mt-12">
      <div className="px-20-xxl py-12-xxl py-8 container px-16-lg px-6 w-90-lg w-full mx-auto bg-blue-900">
        <span className="font-bold text-white text-2xl-xxl text-xl mb-12-xxl mb-10-lg mb-8 inline-block">
          {title}
        </span>
        <div className="flex justify-between flex-wrap">
          {list.map(({ Icon, label, value }) => (
            <ContactInfoCard key={label} label={label} value={value}>
              <Icon />
            </ContactInfoCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
