import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Textarea = ({
  autoFocus,
  id,
  label,
  name,
  disabled = false,
  errorMessage,
  value = '',
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isEmpty, setIsEmpty] = useState(!value.length);

  return (
    <div className="w-full transition-all">
      <div className="relative">
        <label
          htmlFor={id}
          className={`absolute left-16px px-2 bg-white text-sm transition-all ${
            isFocused ? 'text-blue-400' : 'text-gray-600'
          } ${isFocused || !isEmpty ? ' -top-10px' : 'top-1rem'}`}
        >
          {label}
        </label>
        <textarea
          autoFocus={autoFocus}
          name={name}
          id={id}
          rows={8}
          className={`p-4 border rounded-5px w-full outline-none text-blue-950 no-resize ${
            isFocused
              ? 'text-blue-400 border-transparent shadow-blue-400'
              : 'text-blue-500 border-gray-200'
          }`}
          disabled={disabled}
          value={value}
          onFocus={() => setIsFocused(true)}
          onChange={(e) => {
            setIsEmpty(!(e.target.value.length > 0));
            onChange(e);
          }}
          onBlur={() => setIsFocused(false)}
        />
      </div>
      {errorMessage && typeof errorMessage === 'string' && (
        <span className="mt-1 text-sm text-red-600 inline-block">
          * {errorMessage}
        </span>
      )}
    </div>
  );
};

Textarea.propTypes = {
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Textarea;
