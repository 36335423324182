import React from 'react';
import PropTypes from 'prop-types';

const FormSuccess = ({ message }) => {
  if (!message) return null;
  return (
    <div className="text-center w-full grid-span-2 flex justify-center">
      <div className="bg-green-25 p-3 flex items-center gap-2 text-sm text-green-400 font-medium">
        <p>{message}</p>
      </div>
    </div>
  );
};

FormSuccess.propTypes = {
  message: PropTypes.string,
};

export default FormSuccess;
