import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../shared/PageLayout';
import Hero from '../page-components/contact/Hero';
import ContactInfo from '../page-components/contact/ContactInfo';
import ContactForm from '../page-components/contact/ContactForm';
import axios from '../api/axios';
import { graphql } from 'gatsby';

const Contact = ({ serverData }) => {
  const { solutions } = serverData;
  return (
    <PageLayout solutions={solutions}>
      <Hero />
      <ContactInfo />
      <ContactForm />
    </PageLayout>
  );
};

Contact.propTypes = {
  serverData: PropTypes.object,
};

export default Contact;

export async function getServerData() {
  try {
    const { data: solutionData } = await axios.get('/api/solution-level-ones', {
      params: {
        fields: ['name', 'slug'],
        populate: {
          solution_level_twos: {
            fields: ['name', 'slug'],
            populate: {
              solution_level_threes: {
                fields: ['name', 'slug'],
                populate: {
                  products: {
                    fields: ['name', 'slug'],
                  },
                },
              },
            },
          },
        },
      },
    });

    return {
      props: {
        solutions: solutionData?.data.map((item) => ({
          title: item?.attributes?.name,
          url: item?.attributes?.slug,
          subMenu: item?.attributes['solution_level_twos']?.data?.map(
            (subItem) => ({
              title: subItem?.attributes?.name,
              url: subItem?.attributes?.slug,
              subMenu: subItem?.attributes['solution_level_threes']?.data?.map(
                (subSubItem) => ({
                  title: subSubItem?.attributes?.name,
                  url: subSubItem?.attributes?.slug,
                }),
              ),
            }),
          ),
        })),
      },
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["Contact", "Layout"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
